function Text() {
  return (
    <div className="dark">
      <h1 className="zero">FROM DARKNESS COMES LIGHT</h1>
      <h4>
        <span className="boldit">VISIONPRO</span> Black Edition
      </h4>
      <p className="zero">
        Our most advanced beam to date, tuned to deliver 180% more light
        precisely where you need it most. Enhanced with a new triple stage blue
        tint for whiter light.
      </p>
    </div>
  );
}
export default Text;
