function Header() {
  const lucas = "img/lucas-promo3.jpg";
  const bar = process.env.PUBLIC_URL + "/img/VISIONPRO-LOGO.png";
  // const bar = process.env.PUBLIC_URL + "/img/lucas-promo.png";
  return (
    <header className="site-header">
      {/* <a href="/" className="brand"><img src={lucas} alt="brand" /></a> */}
      {/* <p className="logo">Lucas</p> */}
      <img className="logo" src={bar} alt="lucas" />
    </header>
  );
}
export default Header;
