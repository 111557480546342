import React from 'react'

function Footer() {
  return (
    <div className='footer'>
        <p className='zero'><span>ELTA Automotive Limited</span> +44 (0)1675 466 999 | sales@elta.co.uk | www.eltaeurope.com</p>
    </div>
  )
}

export default Footer