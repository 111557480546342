import logo from "./logo.svg";
import "./App.css";
import Video from "./components/Video";
import Compare from "./components/Compare";
import Header from "./components/Header";
import Text from "./components/Text";
import Images from "./components/Images";
import Footer from "./components/Footer";
import Car from "./components/Car";
import Form from "./components/Form";

function App() {
  return (
    <div className="App">
      <Header />
      <Video />
      <Text />
      
      <Compare />
      <Car />
      <Images />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
