import React from "react";
import { useState } from "react";
import axios from "axios";

function Form() {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [body, setBody] = useState("");
  const [price, setPrice] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [dberr, setDberr] = useState([]);
  const [newerr, setNewerr] = useState([]);
  const brand = "visionpro";

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: slug,
      company: body,
      customer: price,
      brand: brand,
    };
    const blog = { name, slug, price };
    // let url = "http://localhost/sandbox/landing/public/api/products";
    // let url = "http://192.168.0.77/sandbox/landing/public/api/products";
    let url = "https://laravel.eltaautomotive.co.uk/api/products";

    axios
      .post(url, data)
      .then(function (response) {
        console.log(response);
        setConfirm(true);
        setName("");
        setSlug("");
        setBody("");
        setPrice("");
        setDberr([]);
        setNewerr([]);
        setTimeout(() => {
          setConfirm(false);
        }, 2000);
      })
      .catch(function (error) {
        let err = error.response.data.errors;
        // console.log(error);
        // console.log(err);
        // console.log(typeof err);
        setDberr(err);
        // const keys = Object.keys(err);
        // console.log(keys);
        const val = Object.values(err);
        const ent = Object.entries(err);
        console.log(ent);

        // console.log(val);
        let result = ent.map((x, y) => ({ [x[0]]: [y[1]] }));
        console.log(result);

        // Object.values(iterator).map((val) => {
        //   return console.log(val);
        // });

        // This works
        let newobj = [];
        for (const [key, value] of ent) {
          // console.log(value);
          console.log("Key value pair");
          console.log(key);
          console.log(value[0]);
          newobj[key] = value[0];
        }
        console.log(newobj);
        setNewerr(newobj);
      });

    // fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     Accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    //   body: JSON.stringify({
    //     name: "something",
    //     slug: "something",
    //     description: "something",
    //     price: "22.99",
    //   }),
    // })
    //   .then((res) => {
    //     console.log(res);
    //     console.log("new blog added");
    //   })
    //   .catch((err) => {
    //     console.log("is this even working");
    //     console.log(err);
    //   });

    console.log(blog);
  };

  return (
    <div>
      <div className="formtext">
        Get in touch using the form below to discover more about VISIONPRO Black
        Edition upgrade bulbs. One of our team will contact you with more
        details.
      </div>
      {/* <ul className="errorlist">
        {Object.values(dberr).map((value) => {
          return <li key={value}>{value}</li>;
        })}
      </ul> */}
      <form className="detailform" onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          placeholder="Please enter name"
          onChange={(e) => setName(e.target.value)}
        />
        <p>{newerr["name"]}</p>
        <input
          type="text"
          value={slug}
          placeholder="Please enter email"
          onChange={(e) => setSlug(e.target.value)}
        />
        <p>{newerr["email"]}</p>
        <input
          type="text"
          value={body}
          placeholder="Please enter company"
          onChange={(e) => setBody(e.target.value)}
        />
        <select value={price} onChange={(e) => setPrice(e.target.value)}>
          <option className="greyedout" value="">
            Choose Customer Type
          </option>
          <option value="end_user">End User</option>
          <option value="garage">Garage</option>
          <option value="motor_factor">Motor Factor</option>
          <option value="retailer">Retailer</option>
        </select>
        <p>{newerr["customer"]}</p>

        {/* <input type="submit" value="Submit" /> */}
        {confirm && (
          <input
            className="confirmed"
            type="submit"
            value="Thank you for your submission"
            disabled
          />
        )}
        {!confirm && (
          <input className="submission" type="submit" value="Submit" />
        )}
      </form>
      {/* {confirm && <p>Thank you for your submission</p>} */}
      {/* <p>{name}</p>
      <p>{slug}</p>
      <p>{body}</p>
      <p>{price}</p> */}
    </div>
  );
}

export default Form;
